@font-face {
    font-family: "Palatino";
    font-style: italic;
    font-weight: 400;
    font-display: swap;
    src: url("/layout/fonts/0ba591b9-f2a1-48ea-88a7-58404933bb61.woff2") format("woff2"),
    url("/layout/fonts/7f280e39-0163-4bbe-b6b5-46604c4a6da1.woff") format("woff");
}

@font-face {
    font-family: "Palatino";
    font-style: italic;
    font-weight: 700;
    font-display: swap;
    src: url("/layout/fonts/56ae551c-5e9d-4487-865d-f8c51363bcd0.woff2") format("woff2"),
    url("/layout/fonts/859152de-1abf-48b2-8dfe-38bad3da17c5.woff") format("woff");
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 400;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/fira-sans-v16-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v16-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/fira-sans-v16-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v16-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/fira-sans-v16-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v16-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

@font-face {
    font-family: 'Fira Sans';
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: local(''),
    url('/layout/fonts/fira-sans-v16-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
    url('/layout/fonts/fira-sans-v16-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
